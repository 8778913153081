import { useEffect, useState } from "react";
import SocialMediaIcons from "../components/SocialMediaIcons";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Landing = ({ setSelectedPage }) => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");

  // State to track the current image index
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = [
    "assets/gridi_kiganjani.jpg",
    "assets/sas.jpg",
    "assets/hardware_development.jpg",
    "assets/software_development.jpg",
    "assets/hardware_development1.jpg",
    "assets/gridi_kiganjani.jpg",
    "assets/sas.jpg",
    "assets/scada.jpg",
    "assets/julio.png",
    "assets/skills-image.png",
  ];

  useEffect(() => {
    // Set up an interval to change the image every 5 seconds
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <section
      id="home"
      className="md:flex md:justify-between md:items-center gap-16 md:h-full py-10"
    >
      {/* IMAGE SECTION */}
      <div className="basis-3/5 z-10 mt-16 md:mt-32 flex justify-center md:order-2">
        {isAboveLarge ? (
          <div
            className="relative z-0 ml-20 before:absolute before:-top-20 before:-left-20 before:rounded-t-[400px]
            before:w-full before:max-w-[400px] md:before:max-w-[600px] before:h-full  before:z-[-1]"
          >
          <img
            alt="profile"
            className="hover:filter hover:saturate-200 transition duration-500 z-10 w-full max-w-[400px] md:max-w-[600px]"
            src={images[currentImageIndex]}
          />
            
          </div>
        ) : (
          <div
            className="relative w-full max-w-[400px] md:max-w-[600px] overflow-hidden"
            style={{
              height: "250px",
              width: "100%",
              margin: "0 auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
          <img
            alt="profile"
            className="hover:filter hover:saturate-200 transition duration-500 z-10 w-full max-w-[400px] md:max-w-[600px]"
            src={images[currentImageIndex]}
          />
          </div>
        )}
      </div>

      {/* MAIN TEXT */}
      <div className="z-30 basis-2/5 mt-12 md:mt-32">
        {/* HEADINGS */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
          onViewportEnter={() => setSelectedPage("home")}
        >
          <p className="text-4xl font-playfair z-10 text-center md:text-start">
            Tanzania Electronics Labs
          </p>

          <p className="mt-10 mb-7 text-sm text-center md:text-start">
            Tanzania Electronics Labs Company Limited (TELabs) was founded with a mission to revolutionize public sector services through ICT, drive innovation in the private sector, and elevate the standards of ICT design and implementation in Tanzania. Our vision is to establish a reputation for excellence and innovation, expanding our services across the nation and the region.
            With expertise spanning electronics engineering, software engineering, and network engineering, we deliver tailored ICT solutions that meet the highest standards. By integrating cutting-edge software technology, robust network infrastructure, and state-of-the-art hardware, our team of highly skilled professionals brings a wealth of experience and resourcefulness to every project. At TELabs, we are committed to transforming ideas into impactful solutions that drive progress and innovation.
          </p>
        </motion.div>

        {/* CALL TO ACTIONS */}
        <motion.div
          className="flex mt-5 justify-center md:justify-start"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <AnchorLink
            className="bg-gradient-rainblue text-deep-blue rounded-sm py-3 px-7 font-semibold
              hover:bg-blue hover:text-white transition duration-500"
            onClick={() => setSelectedPage("contacts")}
            href="#contacts"
          >
            Contact Us
          </AnchorLink>
          <AnchorLink
            className="rounded-r-sm bg-gradient-rainblue py-0.5 pr-0.5"
            onClick={() => setSelectedPage("contacts")}
            href="#contacts"
          >
            <div className="bg-deep-blue hover:text-red transition duration-500 w-full h-full flex items-center justify-center px-10 font-playfair">
              Let's talk.
            </div>
          </AnchorLink>
        </motion.div>

        <motion.div
          className="flex mt-5 justify-center md:justify-start"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <SocialMediaIcons />
        </motion.div>
      </div>
    </section>
  );
};

export default Landing;
