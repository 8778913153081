import { useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import LineGradient from "../components/LineGradient";
import { useState } from 'react';

const Contacts = () => {
    const [response, setResponse] = useState("");

    const values = {
        name: "",
        email: "",
        message: "",
    };

    const {
        register,
        trigger,
        formState: {errors}
    } = useForm();

    const onSubmit = async (e) => {
        e.preventDefault();

        const isValid = await trigger();
        if(!isValid){
            e.preventDefault();
        }

        if(isValid){
            setResponse("Sending message...")

            values.name = e.target.name.value;
            values.email = e.target.email.value;
            values.message = e.target.message.value;

            let backend_url = '';
            if (process.env.REACT_APP_ENV === 'development') {
                // Code to execute in development mode
                backend_url = process.env.REACT_APP_API_URL_DEV;
                console.log("DEV : ", backend_url);
            } else if (process.env.REACT_APP_ENV === 'production') {
                // Code to execute in production mode
                backend_url = process.env.REACT_APP_API_URL_PROD;
                console.log("PROD : ", backend_url);
            }
        
            try{
                const url = backend_url + "api/send_message/";
                console.log("URL : ", url)
                let res = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(values)
                })
                .then(function(response) {
                    return response.text();
                })
                .then((data) => {
                    setResponse(data);
                })
            } catch(err) {
                console.log(err)
            }


        }else {
            console.log("invalid inputs")
        }
    }

    return (
        <section id="contacts" className='py-48'>
            {/* HEADING */}
            <motion.div
                className="flex justify-end w-full"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.5 }}
                variants={{
                    hidden: { opacity: 0, x: -50 },
                    visible: { opacity: 1, x: 0 },
                }}
            >
                <div>
                    <p className="font-playfair font-semibold text-4xl">
                        <span className='text-yellow-300'>Contact</span> Us
                    </p>
                    <div className="flex md:justify-end my-5">
                        <LineGradient width="w-1/2" />
                    </div>
                </div>
            </motion.div>
            
            {/* FORM & IMAGE */}
            <div className="md:flex md:justify-between gap-16 mt-5">
                <motion.div
                    className="basis-1/2 flex justify-center"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                    }}
                >
                    <img src='../assets/contact-image.jpeg' alt='contact' />
                </motion.div>

                <motion.div
                    className="basis-1/2 mt-10 md:mt-0"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.2, duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                    }}
                >
                    <form
                        onSubmit={onSubmit}
                    >
                        <input 
                            className="w-full bg-blue font-semibold placeholder-opaque-black p-3"
                            type="text"
                            placeholder="NAME"
                            {...register('name', {
                                required: true,
                                maxLength: 100
                            })}
                        />
                        {errors.name && (
                            <p className="text-red mt-1">
                                {errors.name.type === 'required' && "This field is required."}
                                {errors.name.type === 'maxLength' && "Max length is 100 chars."}
                            </p>
                        )}

                        <input 
                            className="w-full bg-blue font-semibold placeholder-opaque-black p-3 mt-5"
                            type="text"                            
                            // onChange={() => {console.log("V: ");}}
                            placeholder="EMAIL"
                            {...register('email', {
                                required: true,
                                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            })}
                        />
                         {errors.email && (
                            <p className="text-red mt-1">
                                {errors.email.type === 'required' && "This field is required."}
                                {errors.email.type === 'pattern' && "Invalid email address."}
                            </p>
                        )}

                        <textarea 
                            className="w-full bg-blue font-semibold placeholder-opaque-black p-3 mt-5"
                            type="text"
                            placeholder="MESSAGE"
                            rows={4}
                            cols={10}
                            {...register('message', {
                                required: true,
                                maxLength: 2000
                            })}
                        />
                         {errors.message && (
                            <p className="text-red mt-1">
                                {errors.message.type === 'required' && "This field is required."}
                                {errors.email.type === 'maxLength' && "Max length is 2000 chars."}
                            </p>
                        )}

                        <button
                            type="submit"
                            className="p-5 bg-yellow-300 font-semibold text-deep-blue mt-5 hover:bg-red
                                hover:text-white transition duration-500"
                        >
                            SEND US A MESSAGE
                        </button>

                        <div>
                            {response}
                        </div>

                    </form>
                </motion.div>
            </div>
        </section>
    )
}

export default Contacts;