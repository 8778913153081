import { motion } from "framer-motion";
import LineGradient from "../components/LineGradient";

const OurClientsAndPartners = () => {
    const ourClientsAndPartnersStyles = `mx-auto relative max-w-[400px] h-[350px] flex flex-col
    justify-end p-16 mt-48 before:absolute before:top-[-120px] before:-ml-[110px] before:left-1/2`;

    return (
        <section id="our-clients-and-partners" className="pt-32 pb-16">
        {/* HEADING */}
        <motion.div
            className="md:w-1/3 text-center md:text-left"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.5 }}
            variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
            }}
        >
            <p className="font-playfair font-semibold text-4xl mb-5 text-red">
            Our Clients And Partners
            </p>
            <LineGradient width="mx-auto w-2/4" />
            <p className="mt-2">
                Here are the esteemed companies and organizations we proudly collaborate with:
            </p>
        </motion.div>

        {/* OUR CLIENTS AND PARTNERS */}
        <div className="md:flex md:justify-between gap-8">
            <motion.div
            className="mx-auto relative bg-bluexxx max-w-[400px] h-[350px] flex flex-col justify-end p-16 mt-40
                before:absolute before:top-[-120px] before:-ml-[110px] before:left-1/2 before:content-sifang_logo"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.6 }}
            variants={{
                hidden: { opacity: 0, scale: 0.8 },
                visible: { opacity: 1, scale: 1 },
            }}
            >
            {/* <p className="font-playfair text-6xl">“</p>
            <p className="text-center text-xl">
                TELabs is a local representative of Beijing Sifang Automation Co, Ltd.
            </p> */}
            </motion.div>

            <motion.div
            className="mx-auto relative bg-redxxx max-w-[400px] h-[350px] flex flex-col justify-end p-16 mt-40
                before:absolute before:top-[-120px] before:-ml-[110px] before:left-1/2 before:content-tanesco_logo"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.6 }}
            variants={{
                hidden: { opacity: 0, scale: 0.8 },
                visible: { opacity: 1, scale: 1 },
            }}
            >
            {/* <p className="font-playfair text-6xl">“</p>
            <p className="text-center text-xl">
                TELabs is collaborating with TANESCO to implement Automation solutions for Grid monitoring.
            </p> */}
            </motion.div>

            {/* <motion.div
            className="mx-auto relative bg-green-300xxx max-w-[400px] h-[350px] flex flex-col justify-end p-16 mt-48
                before:absolute before:top-[-120px] before:-ml-[110px] before:left-1/2 before:content-person3"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.4, duration: 0.6 }}
            variants={{
                hidden: { opacity: 0, scale: 0.8 },
                visible: { opacity: 1, scale: 1 },
            }}
            >
            <p className="font-playfair text-6xl">“</p>
            <p className="text-center text-xl">
                Fames odio in at. At magna ornare dictum lectus.
            </p>
            </motion.div> */}
        </div>
        </section>
    );
};

export default OurClientsAndPartners;
