import { useState } from "react";
import { motion } from "framer-motion";
import AnchorLink from "react-anchor-link-smooth-scroll";
import useMediaQuery from "../hooks/useMediaQuery";

const Link = ({label, page, selectedPage, setSelectedPage}) => {
    const lowerCasePage = page.toLowerCase();
    return (
        <AnchorLink 
            className={`${selectedPage === lowerCasePage ? "text-yellow-300" : "" }
                hover: text-yellow transition duration-500`}
                href={`#${lowerCasePage}`}
                onClick={() => setSelectedPage(lowerCasePage)}
        >
            {label}
        </AnchorLink>
    )
}

const Navbar = ({ isTopOfPage, selectedPage, setSelectedPage }) => {
    const [isMenuToggled, setIsMenuToggled] = useState(false);
    const isAboveSmallScreens = useMediaQuery("(min-width: 768px)");
    const navbarBackground = isTopOfPage ? "" : "bg-blue";
    return(
        <nav className={`${navbarBackground} z-40 w-full fixed top-0 py-6`}>
            <div className="flex items-center justify-between mx-auto w-5/6">
                {/* <h4 className="font-playfair text-3xl font-bold">
                    TELabs
                </h4> */}

                {isAboveSmallScreens ? (
                    <motion.div
                        className="relative justify-start p-0 ml-20 mt-10
                            before:absolute before:top-[-65px] before:-ml-[110px] before:left-1/2 before:content-telabs_logo"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ duration: 0.6 }}
                        variants={{
                            hidden: { opacity: 0, scale: 0.8 },
                            visible: { opacity: 1, scale: 1 },
                        }}
                    >
                    </motion.div>
                ) : (
                    <motion.div
                        className="relative justify-start p-0 ml-20 mt-10  
                            before:absolute before:top-[-65px] before:-ml-[110px] before:left-1/2 before:content-telabs_logo2"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ duration: 0.6 }}
                        variants={{
                            hidden: { opacity: 0, scale: 0.8 },
                            visible: { opacity: 1, scale: 1 },
                        }}
                    >
                    </motion.div>
                )
                }

                {/* DESKTOP NAV */}
                {isAboveSmallScreens ? (
                    <div className="flex justify-between gap-16 font-opensans text-sm font-semibold">
                        <Link 
                            label="Home"
                            page="Home"
                            selectedPage={selectedPage}
                            setSelectedPage={setSelectedPage}
                        />
                        <Link 
                            label="Services"
                            page="Services"
                            selectedPage={selectedPage}
                            setSelectedPage={setSelectedPage}
                        />
                        <Link 
                            label="Products"
                            page="Products"
                            selectedPage={selectedPage}
                            setSelectedPage={setSelectedPage}
                        />
                        <Link
                            label="Projects"
                            page="Projects"
                            selectedPage={selectedPage}
                            setSelectedPage={setSelectedPage}
                        />
                        <Link
                            label="Our Clients & Partners"
                            page="our-clients-and-partners"
                            selectedPage={selectedPage}
                            setSelectedPage={setSelectedPage}
                        />
                        <Link 
                            label="Contacts"
                            page="Contacts"
                            selectedPage={selectedPage}
                            setSelectedPage={setSelectedPage}
                        />
                    </div>
                ) : (
                    <button 
                        className="rounded-full bg-red p-2"
                        onClick={() => {setIsMenuToggled(!isMenuToggled); }}
                    >
                        <img alt="menu-icon" src="../assets/menu-icon.svg" />
                    </button>
                )
                }

                {/* MOBILE MENU POPUP */}
                {!isAboveSmallScreens && isMenuToggled && (
                    <div className="fixed right-0 bottom-0 h-full bg-blue w-[300px]">
                        {/* CLOSE ICON */}
                        <div className="flex justify-end p-12">
                            <button onClick={() => setIsMenuToggled(!isMenuToggled)} > 
                                <img alt="close-icon" src="../assets/close-icon.svg" />
                            </button>
                        </div>

                        {/* MENU ITEMS */}
                        <div className="flex flex-col gap-10 ml-[33%] text-2xl text-deep-blue">
                            <Link
                                label="Home"
                                page="Home"
                                selectedPage={selectedPage}
                                setSelectedPage={setSelectedPage}
                            />
                            <Link
                                label="Services"
                                page="Services"
                                selectedPage={selectedPage}
                                setSelectedPage={setSelectedPage}
                            />
                            <Link
                                label="Products"
                                page="Products"
                                selectedPage={selectedPage}
                                setSelectedPage={setSelectedPage}
                            />
                            <Link
                                label="Projects"
                                page="Projects"
                                selectedPage={selectedPage}
                                setSelectedPage={setSelectedPage}
                            />
                            <Link
                                label="Our Clients & Partners"
                                page="our-clients-and-partners"
                                selectedPage={selectedPage}
                                setSelectedPage={setSelectedPage}
                            />
                            <Link
                                label="Contacts"
                                page="Contacts"
                                selectedPage={selectedPage}
                                setSelectedPage={setSelectedPage}
                            />
                        </div>
                    </div>
                ) }
            </div>
            
        </nav>
    )

}

export default Navbar;