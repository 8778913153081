import AnchorLink from "react-anchor-link-smooth-scroll"

const DotGroup = ({ selectedPage, setSelectedPage }) => {
    const selectedStyles = `relative  bg-yellow-300 before:absolute before:w-6 before:h-6
        before:rounded-full before:border-2 before:border-yellow before:left-[-50%]
        before:top-[-50%]`
    return (
        <div className="flex flex-col gap-6 fixed top-[60%] right-7">
            <AnchorLink 
                className={`${selectedPage === 'home' ? selectedStyles : "bg-dark-grey" }
                    w-3 h-3 rounded-full`}
                    href="#home"
                    onClick={() => setSelectedPage('home')}
            />
             <AnchorLink 
                className={`${selectedPage === 'services' ? selectedStyles : "bg-dark-grey" }
                    w-3 h-3 rounded-full`}
                    href="#services"
                    onClick={() => setSelectedPage('services')}
            />
            <AnchorLink 
                className={`${selectedPage === 'products' ? selectedStyles : "bg-dark-grey" }
                    w-3 h-3 rounded-full`}
                    href="#products"
                    onClick={() => setSelectedPage('products')}
            />
            <AnchorLink 
                className={`${selectedPage === 'projects' ? selectedStyles : "bg-dark-grey" }
                    w-3 h-3 rounded-full`}
                    href="#projects"
                    onClick={() => setSelectedPage('projects')}
            />
            <AnchorLink 
                className={`${selectedPage === 'our-clients-and-partners' ? selectedStyles : "bg-dark-grey" }
                    w-3 h-3 rounded-full`}
                    href="#our-clients-and-partners"
                    onClick={() => setSelectedPage('our-clients-and-partners')}
            />
            <AnchorLink 
                className={`${selectedPage === 'contacts' ? selectedStyles : "bg-dark-grey" }
                    w-3 h-3 rounded-full`}
                    href="#contacts"
                    onClick={() => setSelectedPage('contacts')}
            />
        </div>
    )
}

export default DotGroup;