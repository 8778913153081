import SocialMediaIcons from "../components/SocialMediaIcons";

const Footer = () => {
    return (
        <footer className="h-120 bg-blue pt-10">
            <div className="w-5/6 mx-auto">
                <SocialMediaIcons />
                <div className="md:flex justify-center md:justify-between text-center">
                    <p className="font-playfair font-semibold text-2xl text-black">
                        TANZANIA ELECTRONICS LABS COMPANY LIMITED
                    </p>
                </div>

                <div className="md:flex justify-center md:justify-between text-center">
                    <p className="font-playfair font-semibold text-1xl text-black">
                        Phone number: +255766032160, +255684444997, +255672120941
                    </p>
                </div>
                <div className="md:flex justify-center md:justify-between text-center">
                    <p className="font-playfair font-semibold text-1xl text-black">
                        Email: giulionyakunga@gmail.com, info@telabs.co.tz
                    </p>
                </div>
                <div className="md:flex justify-center md:justify-between text-center">
                    <p className="font-playfair font-semibold text-1xl text-black">
                        Address: Uganda Street, Kijitonyama, Dar es Salaam
                    </p>
                </div>

                <div className="md:flex justify-center md:justify-between text-center mt-10">
                    <p className="font-playfair text-md text-white">
                        @2024 TELabs. All Rights Reserved.
                    </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;