import LineGradient from "../components/LineGradient";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";

const Products = () => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <section id="products" className="pt-10 pb-24">
      {/* HEADER AND IMAGE SECTION */}
      <div className="md:flex md:justify-between md:gap-16 mt-32">
        <motion.div
          className="md:w-1/3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="font-playfair font-semibold text-4xl mb-5">
            OUR <span className="text-red">Products</span>
          </p>
          <LineGradient width="w-1/3" />
          <p className="mt-2 mb-7">
            At TELabs, we offer a diverse range of innovative solutions, encompassing both software and hardware. We specialize in delivering cutting-edge automation systems tailored to meet your unique needs, seamlessly integrating advanced software and hardware technologies to drive efficiency and innovation
          </p>
        </motion.div>

        <div className="mt-16 md:mt-0">
          {isAboveLarge ? (
            <div
              className="relative z-0 ml-20 before:absolute before:-top-10 before:-left-10
              before:w-full before:h-full before:border-2 before:border-blue before:z-[-1]"
            >
              <img
                alt="products"
                className="z-10"
                src="assets/skills-image.png"
              />
            </div>
          ) : (
            <img alt="products" className="z-10" src="assets/skills-image.png" />
          )}
        </div>
      </div>

      {/* PRODUCTS */}
      <div className="md:flex md:justify-between mt-16 gap-32">
        {/* PRODUCT 1 */}
        <motion.div
          className="md:w-1/3 mt-1"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">01</p>
              <p className="font-playfair font-semibold text-1xl mt-3">
                GRIDI KIGANJANI
              </p>
            </div>
            {/* <div className="w-1/2 md:w-3/4 h-32 bg-blue absolute right-0 top-0 z-[-1]" /> */}
          </div>
          <p className="mt-1">
            Gridi Kiganjani is a innovative hardware and software solution designed for monitoring power generation in Tanzanian power plants directly from your mobile phone. With its advanced capabilities, Gridi Kiganjani provides real-time insights into power plant operations, generates detailed reports, and ensures seamless distribution via email. This innovative solution streamlines grid monitoring, enhances efficiency, and empowers stakeholders with actionable data at their fingertips.
            Experience the future of power plant monitoring with Gridi Kiganjani.
          </p>
        </motion.div>

        {/* PRODUCT 2 */}
        <motion.div
          className="md:w-1/3 mt-1"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">02</p>
              <p className="font-playfair font-semibold text-1xl mt-3">
                AI-BASED AUTORECLOSER MONITORING SYSTEM
              </p>
            </div>
            {/* <div className="w-1/2 md:w-3/4 h-32 bg-blue absolute right-0 top-0 z-[-1]" /> */}
          </div>
          <p className="mt-1">          
            Our AI-Based Auto Recloser Monitoring System is an advanced solution designed to monitor auto reclosers within distribution power networks. This system enables utilities to gather critical real-time data, providing actionable insights for effective decision-making and load forecasting. By leveraging AI technology, it enhances operational efficiency, reduces downtime, and supports predictive maintenance for a more reliable power distribution network.
            Discover how this system can transform your network management and optimize energy distribution.
          </p>
        </motion.div>

        {/* PRODUCT 3 */}
        <motion.div
          className="md:w-1/3 mt-1"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">03</p>
              <p className="font-playfair font-semibold text-1xl mt-3">
                WATER USAGE MONITORING SYSTEM
              </p>
            </div>
            {/* <div className="w-1/2 md:w-3/4 h-32 bg-red absolute right-0 top-0 z-[-1]" /> */}
          </div>
          <p className="mt-1">
            Our Water Usage Monitoring System is an innovative solution designed to empower both domestic and industrial consumers to efficiently track and manage their water consumption. This system seamlessly integrates advanced hardware with user-friendly software to provide real-time data, insights, and control over water usage. It’s the perfect tool for promoting water conservation, reducing wastage, and optimizing resource management.
            Explore how this system can help you make smarter water usage decisions while contributing to a sustainable future.
          </p>
        </motion.div>     
      </div>

      <div className="md:flex md:justify-between mt-16 gap-32">
        {/* PRODUCT 4 */}
        <motion.div
          className="md:w-1/3 mt-1"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">04</p>
              <p className="font-playfair font-semibold text-1xl mt-3">
                IEC-60870-5-101 TO IEC-60870-5-104 PROTOCOL CONVERTER
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-yellow absolute right-0 top-0 z-[-1]" />
          </div>
          <p className="mt-1">
            Our IEC-60870-5-101 to IEC-60870-5-104 Protocol Converter is a specialized device designed to bridge the communication gap between devices using different versions of the IEC 60870-5 protocol. These protocols are widely used in power system automation for telecontrol, telemetry, and monitoring. This converter ensures seamless interoperability, enabling efficient data exchange and integration between systems, regardless of the protocol version in use.
            Optimize your power system automation with our reliable protocol conversion solution.
          </p>
        </motion.div>   

        {/* PRODUCT 5 */}
        <motion.div
          className="md:w-1/3 mt-1"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">05</p>
              <p className="font-playfair font-semibold text-1xl mt-3">
                IEC-61850 TO IEC-104 PROTOCOL CONVERTER
              </p>
            </div>
            {/* <div className="w-1/2 md:w-3/4 h-32 bg-blue absolute right-0 top-0 z-[-1]" /> */}
          </div>
          <p className="mt-1">
            Our IEC-61850 to IEC-104 Protocol Converter is a highly efficient device designed to enable seamless communication between systems using the IEC 61850 and IEC 60870-5-104 protocols. These protocols are critical standards in power system automation, widely used for telecontrol, telemetry, and monitoring. This converter ensures interoperability between different systems, facilitating reliable data exchange and integration across diverse power network infrastructures. Enhance your power system connectivity with our dependable protocol conversion solution.
          </p>
        </motion.div>

        {/* PRODUCT 6 */}
        <motion.div
          className="md:w-1/3 mt-1"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">06</p>
              <p className="font-playfair font-semibold text-1xl mt-3">
                AUTOMATED TELECOM KPI REPORT GENERATOR
              </p>
            </div>
            {/* <div className="w-1/2 md:w-3/4 h-32 bg-red absolute right-0 top-0 z-[-1]" /> */}
          </div>
          <p className="mt-1">
            Our Automated Telecom KPI Report Generator is a powerful tool designed to streamline operations for OSS and NOC teams. By automating the generation and distribution of KPI reports, this solution eliminates the need for manual handling of Excel sheets, saving time and reducing errors. It delivers accurate, timely reports directly to stakeholders, enhancing operational efficiency and decision-making. Simplify your KPI reporting process with our advanced automation solution.
          </p>
        </motion.div>      
      </div>
    </section>
  );
};

export default Products;