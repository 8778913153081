import LineGradient from "../components/LineGradient";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";

const Services = () => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <section id="services" className="pt-20 pb-24">
      {/* HEADINGS */}
      <motion.div
        className="md:w-2/5 mx-auto text-center"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: -50 },
          visible: { opacity: 1, y: 0 },
        }}
      >
        <div>
          <p className="font-playfair font-semibold text-4xl">
            OUR <span className="text-red">Services</span>
          </p>
          <div className="flex justify-center mt-5">
            <LineGradient width="w-2/3" />
          </div>
        </div>
        <p className="mt-10 mb-10">
          At TELabs, we offer a comprehensive range of ICT services designed to meet the diverse needs of our clients
        </p>
      </motion.div>

      {/* SERVICE 1 */}
      <div className="md:flex md:justify-between md:gap-16 mt-32">
        <motion.div
          className="md:w-1/3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="font-playfair font-semibold text-2xl mb-1">
            SUPERVISORY CONTROL AND DATA ACQUISITION <span className="text-red">(SCADA)</span>
          </p>
          <LineGradient width="w-1/3" />
          <p className="mt-2 mb-7">
            We specialize in the design and installation of Substation Automation Systems (SAS) and Human Machine Interface (HMI) solutions to enhance the automation, monitoring, and control of electrical and water infrastructure systems. With extensive hands-on experience in Sifang, IGrid, and ABB Systems, we deliver reliable, efficient, and user-friendly solutions tailored to meet the demands of critical infrastructure operations.
            Partner with us to elevate your system's performance and operational efficiency with cutting-edge SCADA expertise.
          </p>
        </motion.div>

        <div className="mt-16 md:mt-0">
          {isAboveLarge ? (
            // <div
            //   className="relative z-0 ml-20 before:absolute before:-top-10 before:-left-10
            //   before:w-full before:h-full before:border-2 before:border-blue before:z-[-1]"
            // >
            //   <img
            //     alt="services"
            //     className="z-10"
            //     src="assets/sas.jpg"
            //   />
            // </div>
            <img
                alt="services"
                className="z-10"
                src="assets/sas.jpg"
            />
          ) : (
            <img alt="services" className="z-10" src="assets/sas_1.jpg" />
          )}
        </div>
      </div>

      {/* SERVICE 2 */}
      <div className="md:flex md:justify-between md:gap-16 mt-32">
        {isAboveLarge && (
          <div className="mt-16 md:mt-0">
            <img alt="services" className="z-10" src="assets/scada.jpg" />
          </div>
        )
        }
        <motion.div
          className="md:w-1/3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="font-playfair font-semibold text-2xl mb-1">
            CUSTOM SCADA SYSTEM DEVELOPMENT <span className="text-red">(PROCESS MONITORING)</span>
          </p>
          <LineGradient width="w-1/3" />
          <p className="mt-2 mb-7">
            We provide end-to-end development of custom Supervisory Control and Data Acquisition (SCADA) systems designed to meet your unique operational requirements. Our SCADA solutions deliver real-time data monitoring, advanced analytics, and comprehensive control functionalities to optimize your system performance and improve decision-making.
          </p>
        </motion.div>

        {!isAboveLarge && (
          <div className="mt-16 md:mt-0">
            <img alt="services" className="z-10" src="assets/scada.jpg" />
          </div>
        )
        }
      </div>

      {/* SERVICE 3 */}
      <div className="md:flex md:justify-between md:gap-16 mt-32">
        <motion.div
          className="md:w-1/3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="font-playfair font-semibold text-2xl mb-2">
            HARDWARE DEVELOPMENT <span className="text-red">SERVICES</span>
          </p>
          <LineGradient width="w-1/3" />
          <p className="mt-2 mb-7">
            We specialize in the design and development of electronic circuits for a wide range of applications, including industrial automation, home automation, and Internet of Things (IoT) solutions. Our custom-built hardware is designed to deliver reliability, efficiency, and seamless integration into your systems, ensuring optimal performance and innovation tailored to your needs.
            Empower your automation and IoT projects with our cutting-edge hardware solutions!
          </p>
        </motion.div>

        <div className="mt-16 md:mt-0">
          {isAboveLarge ? (         
            <img alt="services" className="z-10" src="assets/hardware_development.jpg" />

          ) : (
            <img alt="services" className="z-10" src="assets/hardware_development.jpg" />
          )}
        </div>
      </div>

      {/* SERVICE 4 */}
      <div className="md:flex md:justify-between md:gap-16 mt-32">
        {isAboveLarge && (
          <div className="mt-16 md:mt-0">
            <img alt="services" className="z-10" src="assets/software_development.jpg" />
          </div>
        )}
        <motion.div
          className="md:w-1/3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="font-playfair font-semibold text-2xl mb-1">
            SOFTWARE DEVELOPMENT <span className="text-red">SERVICES</span>
          </p>
          <LineGradient width="w-1/3" />
          <p className="mt-2 mb-7">
            We offer professional design and development services for customized web-based and mobile applications. Our solutions are tailored to meet your specific business needs, providing innovative, user-friendly, and efficient software that enhances productivity and delivers exceptional user experiences.
          </p>
        </motion.div>
        {!isAboveLarge && (
          <div className="mt-16 md:mt-0">
            <img alt="services" className="z-10" src="assets/software_development_1.jpg" />
          </div>
        )}
      </div>
    </section>
  );
};

export default Services;